var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "slide" },
    [
      _c("div", { staticClass: "header d-flex w-100" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "b-toggle",
                rawName: "v-b-toggle",
                value: `collapse-${_vm.index}`,
                expression: "`collapse-${index}`"
              }
            ],
            staticClass: "slide-header order"
          },
          [_vm._v(_vm._s(_vm.index + 1))]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "b-toggle",
                rawName: "v-b-toggle",
                value: `collapse-${_vm.index}`,
                expression: "`collapse-${index}`"
              }
            ],
            staticClass: "slide-header name"
          },
          [_vm._v(_vm._s(_vm.configData.handle))]
        ),
        _c(
          "div",
          { staticClass: "dot-menu" },
          [
            _c(
              "b-dropdown",
              {
                attrs: {
                  "toggle-class": "dot-button",
                  "no-caret": "",
                  dropup: "",
                  "no-flip": "",
                  right: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "button-content",
                    fn: function() {
                      return [
                        _c("b-icon", {
                          attrs: {
                            icon: "three-dots-vertical",
                            "aria-hidden": "true",
                            scale: ".8"
                          }
                        })
                      ]
                    },
                    proxy: true
                  }
                ])
              },
              [
                _c(
                  "b-dropdown-item",
                  {
                    on: {
                      click: function($event) {
                        return _vm.$emit("open-rename-modal", _vm.index)
                      }
                    }
                  },
                  [
                    _vm._v(" Rename "),
                    _c("b-icon", {
                      attrs: {
                        icon: "pencil-square",
                        "aria-hidden": "true",
                        scale: ".8"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "b-dropdown-item",
                  {
                    on: {
                      click: function($event) {
                        return _vm.$emit("delete-item", _vm.index)
                      }
                    }
                  },
                  [
                    _vm._v(" Delete "),
                    _c("b-icon", {
                      attrs: {
                        icon: "trash",
                        "aria-hidden": "true",
                        scale: ".8"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "navigation d-flex flex-column" },
          [
            _c("b-icon", {
              attrs: { icon: "arrow-up", "aria-hidden": "true", scale: ".4" },
              on: {
                click: function($event) {
                  return _vm.moveUp(_vm.index)
                }
              }
            }),
            _c("b-icon", {
              attrs: { icon: "arrow-down", "aria-hidden": "true", scale: ".4" },
              on: {
                click: function($event) {
                  return _vm.moveDown(_vm.index)
                }
              }
            })
          ],
          1
        )
      ]),
      _c(
        "b-collapse",
        {
          staticClass: "pt-3",
          attrs: { id: `collapse-${_vm.index}` },
          on: {
            show: function($event) {
              _vm.isCollapseOpen = true
            },
            hidden: function($event) {
              _vm.isCollapseOpen = false
            }
          },
          model: {
            value: _vm.isCollapseOpen,
            callback: function($$v) {
              _vm.isCollapseOpen = $$v
            },
            expression: "isCollapseOpen"
          }
        },
        [
          _vm.isCollapseOpen
            ? _c(
                "div",
                _vm._l(_vm.pluginFieldConfig, function(
                  configItem,
                  configIndex
                ) {
                  return _c(configItem.fieldType, {
                    key: configIndex,
                    tag: "component",
                    attrs: {
                      handle: configItem.handle + "___" + _vm.index,
                      label: configItem.title,
                      desc: configItem.desc,
                      enter: configItem.enter,
                      "value-from-db": _vm.settingsData[configItem.handle]
                    },
                    on: {
                      "update-field": function($event) {
                        return _vm.updateSettingValue($event, configItem.handle)
                      },
                      "file-removed": function($event) {
                        return _vm.updateSettingValue($event, configItem.handle)
                      }
                    }
                  })
                }),
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }