<template>
  <div class="slide">
    <div class="header d-flex w-100">
      <div class="slide-header order" v-b-toggle="`collapse-${index}`">{{ index + 1 }}</div>
      <div class="slide-header name" v-b-toggle="`collapse-${index}`">{{ configData.handle }}</div>
      <div class="dot-menu">
        <b-dropdown
          toggle-class="dot-button"
          no-caret
          dropup
          no-flip
          right
        >
          <template #button-content>
            <b-icon icon="three-dots-vertical" aria-hidden="true" scale=".8"></b-icon>
          </template>
          <b-dropdown-item
            @click="$emit('open-rename-modal', index)"
          >
            Rename
            <b-icon icon="pencil-square" aria-hidden="true" scale=".8"></b-icon>
          </b-dropdown-item>
          <b-dropdown-item
            @click="$emit('delete-item', index)"
          >
            Delete
            <b-icon icon="trash" aria-hidden="true" scale=".8"></b-icon>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="navigation d-flex flex-column">
        <b-icon
          icon="arrow-up"
          aria-hidden="true"
          scale=".4"
          @click="moveUp(index)"
        ></b-icon>
        <b-icon
          icon="arrow-down"
          aria-hidden="true"
          scale=".4"
          @click="moveDown(index)"
        ></b-icon>
      </div>
    </div>
    <b-collapse
      :id="`collapse-${index}`"
      class="pt-3"
      v-model="isCollapseOpen"
      @show="isCollapseOpen = true"
      @hidden="isCollapseOpen = false"
    >
      <div v-if="isCollapseOpen">
        <component
          v-for="(configItem, configIndex) in pluginFieldConfig"
          :key="configIndex"
          :is="configItem.fieldType"
          :handle="configItem.handle + '___' + index"
          :label="configItem.title"
          :desc="configItem.desc"
          :enter="configItem.enter"
          :value-from-db="settingsData[configItem.handle]"
          @update-field="updateSettingValue($event, configItem.handle)"
          @file-removed="updateSettingValue($event, configItem.handle)"
        />
      </div>
    </b-collapse>
  </div>
</template>

<script>
import CheckboxRow from '@/components/helper/CheckboxRow.vue';
import ImageUploadRow from '@/components/helper/ImageUploadRow.vue';
import InputRow from '@/components/helper/InputRow.vue';
import FroalaRow from '@/components/helper/FroalaRow.vue';

export default {
  name: 'PluginIntroRowSlide',
  components: {
    CheckboxRow,
    InputRow,
    ImageUploadRow,
    FroalaRow,
  },
  props: {
    configData: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    closeCollapsible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCollapseOpen: false, // used for lazy loading
      pluginFieldConfig: [
        {
          title: this.$t('settings.plugins.intro.imageLink'),
          desc: this.$t('settings.plugins.intro.imageLinkSubText'),
          fieldType: 'ImageUploadRow',
          handle: 'image',
        },
        {
          title: this.$t('settings.plugins.intro.imageDisplay'),
          desc: this.$t('settings.plugins.intro.imageDisplaySubText'),
          fieldType: 'CheckboxRow',
          handle: 'hideImage',
        },
        {
          title: this.$t('settings.plugins.intro.text'),
          fieldType: 'FroalaRow',
          handle: 'text',
          desc: null,
          label: null,
          enter: false,
        },
        {
          title: this.$t('settings.plugins.intro.buttonText'),
          fieldType: 'InputRow',
          handle: 'buttonText',
        },
      ],
      settingsData: null,
      imageObject: {
        url: null,
        width: null,
        height: null,
        focalpoint: {
          x: 50,
          y: 50,
        },
      },
    };
  },
  created() {
    this.settingsData = { ...this.configData };
    this.imageObject.url = this.settingsData.image;
    this.settingsData.image = this.imageObject;
  },
  watch: {
    closeCollapsible(val) {
      if (val) {
        this.isCollapseOpen = false;
      }
      this.$emit('collapsible-closed', false);
    },
    configData(val) {
      this.settingsData = { ...val };
      this.imageObject.url = this.settingsData.image;
      this.settingsData.image = this.imageObject;
    },
  },
  methods: {
    moveUp() {
      this.isCollapseOpen = false;
      this.$emit('move-up', this.index);
    },
    moveDown() {
      this.isCollapseOpen = false;
      this.$emit('move-down', this.index);
    },
    updateSettingValue(value, handle) {
      this.settingsData[handle] = value;
      const emitSettingsObject = { ...this.settingsData };
      if (this.settingsData.image) {
        const { url } = this.settingsData.image;
        if (url) {
          emitSettingsObject.image = url;
        }
      }
      this.$emit('update-setting-row', emitSettingsObject, this.index);
    },
  },
};
</script>

<style lang="scss" scoped>
.slide {
  border: 1px solid #D0D4D8;
  padding: 7px;
  margin-bottom: 8px;

  .header {
    div {
      flex-basis: 30px;
      text-align: center;
    }

    .name {
      flex-grow: 2;
      text-align: left;
    }

    .navigation {
      flex-basis: 10px;
      svg {
        cursor: pointer;
      }
    }
  }
}

/deep/ .dropdown .btn-secondary {
  background-color: #fff;
  border-color: #fff;
  color: #000;
  padding: 0;
  line-height: 10px;

  &:hover {
    background-color: #fff;
    border-color: #fff;
    color: #000;
  }
}

/deep/ .dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #e0e2e4;

  svg {
    padding-left: 5px;
    margin-left: 5px;
  }
}
</style>
